import Avery_Hazelett from '../assets/Avery_Hazelett/Avery_320x320.webp';
import Bailey_Vohsen_1 from '../assets/Bailey_Vohsen/Bailey 1 320x320.webp';
import Bailey_Vohsen_2 from '../assets/Bailey_Vohsen/Bailey 2 320x320.webp';
import Bailey_Vohsen_3 from '../assets/Bailey_Vohsen/Bailey 3 320x320.webp';
import Buddy_Baldwin from '../assets/Buddy_Baldwin/Buddy_320x320.webp';
import Cali_Repple_1 from '../assets/Cali_Repple/Cali_11.webp';
import Cali_Repple_2 from '../assets/Cali_Repple/Cali_2_320h.webp';
import Clark_Bredenkamp from '../assets/Clark_Bredenkamp/Clark_420x320.webp';
import Oliver_McDonald_1 from '../assets/Oliver_McDonald/Oliver_1.webp';
import Oliver_McDonald_2 from '../assets/Oliver_McDonald/Oliver_2.webp';
import Oliver_McDonald_3 from '../assets/Oliver_McDonald/Oliver_3.webp';
import Radar from '../assets/Radar_Blazier/Radar.webp';
import Radar_handsome_car from '../assets/Radar_Blazier/Radar_handsome_car.webp';
import Radar_kitchen_rug from '../assets/Radar_Blazier/Radar_kitchen_rug.webp';
import Rollo_Keimon from '../assets/Rollo_Keimon/Rollo.webp';
import Sallie_Schuette from '../assets/Sallie_Schuette/Sallie.webp';
import Sami_Boschert from '../assets/Sami_Boschert/Sami.webp';
import Titan_Moyer from '../assets/Titan_Moyer/Titan.webp';
import Winnie_Arnold from '../assets/Winnie_Arnold/Winnie.webp';
import Woodstock_Seufert from '../assets/Woodstock_Seufert/Woodstock 320x320.webp';

export enum ResourceId {
    QualityOfLife,
    CaninePainScale,
    FelineGrimaceScale,
    CAETA,
}

export const Resources: { id: ResourceId; name: string; url: string }[] = [
    {
        id: ResourceId.QualityOfLife,
        name: 'Quality Of Life scale',
        url: 'https://alpenglowvets.com/wp-content/uploads/2017/09/QualityLife_E.pdf',
    },
    {
        id: ResourceId.CaninePainScale,
        name: 'Canine Pain Scale',
        url: 'https://vetmedbiosci.colostate.edu/vth/wp-content/uploads/sites/7/2020/12/canine-pain-scale.pdf',
    },
    {
        id: ResourceId.FelineGrimaceScale,
        name: 'Feline Grimace Scale',
        url: 'https://www.felinegrimacescale.com/_files/ugd/103d43_22a9712fe2f145368a6584aab0abc9aa.pdf',
    },
    {
        id: ResourceId.CAETA,
        name: 'Companion Animal Euthanasia Training Academy',
        url: 'https://caetainternational.com/',
    },
];

export const adultBooks = [
    {
        title: 'Healing After Loss: Daily Meditations for Working Through Grief',
        author: 'Martha Whitmore Hickman',
    },
    {
        title: 'And I Love You Still…',
        author: 'Julianne Corbin',
    },
    {
        title: 'When Your Pet Dies: A Guide to Mourning, Remembering, and Healing',
        author: 'Alan D. Wolfelt',
    },
    {
        title: 'The Pet Loss Companion',
        author: 'Ken Dolan-Del Vecchio and Nancy Saxton-Lopez',
    },
    {
        title: 'Children and Pet Loss: A Guide for Helping',
        author: 'Marty Tousley',
    },
];

export const childrensBooks = [
    {
        title: 'Cat Heaven',
        author: 'Cynthia Ryant',
    },
    {
        title: 'Dog Heaven',
        author: 'Cynthia Ryant',
    },
    {
        title: 'When a Pet Dies',
        author: 'Fred Rogers',
    },
    {
        title: 'Pawprints in the Stars: A Farewell and Journal for a Beloved Pet',
        author: 'Warren Hanson',
    },
    {
        title: 'Don’t Say Goodbye, Just Say See You!',
        author: 'Patricia A. Brill',
    },
];

export const localPetLossResources = [
    {
        href: 'https://www.strayrescue.org/griefgroup224',
        preText: '',
        linkText: 'Stray Rescue of St. Louis Grief Group',
    },
    {
        href: 'https://wellnessalley.com/pet-related-counseling-services/',
        preText: 'Allison White, ACSW, LCSW, CCDP-D at ',
        linkText: 'Wellness Alley, LLC',
    },
];

export const nationalSuicidePreventionResources = [
    {
        href: 'tel:18002738255',
        preText: '',
        linkText: '1-800-273-8255',
    },
    {
        href: 'https://suicidepreventionlifeline.org',
        preText: 'Online chat option: ',
        linkText: 'suicidepreventionlifeline.org',
    },
];

export const missouriCrisisResources = [
    {
        href: 'sms:839863?body=HAND',
        preText: '',
        linkText: 'Text “HAND” to 839863',
    },
];

export const petLossSupportLines = [
    {
        href: 'tel:5088397966',
        preText: 'Tufts University Pet Loss Support Hotline: ',
        linkText: '508-839-7966',
    },
    {
        href: 'tel:6303251600',
        preText: 'Chicago Veterinary Medical Association Support Line: ',
        linkText: '630-325-1600',
    },
    {
        href: 'tel:6072187457',
        preText: 'Cornell University Pet loss Support Hotline: ',
        linkText: '607-218-7457',
    },
    {
        href: 'https://www.aplb.org/chat-with-us/',
        preText: '',
        linkText: 'Association for Pet Loss and Bereavement',
    },
];

export const weeklyChatRooms = [
    {
        href: 'https://www.everlifememorials.com/Pet-Loss-Support-Groups-s/443.htm',
        preText: '',
        linkText: 'Everlife Memorials',
    },
    {
        href: 'http://www.griefhealing.com/index.htm',
        preText: '',
        linkText: 'Grief Healing',
    },
];

export const memorialsAndTestimonialsLink = 'https://form.jotform.com/233013945964157';

export const memorialsData: { images: string[]; name: string; texts: string[] }[] = [
    {
        images: [Titan_Moyer],
        name: 'Titan',
        texts: [
            'Titan was with us for about 15 years and he made us so happy in those years. He lived a long great life and he was as comfortable as possible through this tough decision',
        ],
    },
    {
        images: [Sami_Boschert],
        name: 'Sami',
        texts: [
            'Sami was a 15-year-old Morkie.  She was energetic and full of life through the end.  She loved throwing her toys around the house.  She was loved by our family and many of our friends that knew her.  She battled kidney disease for at least 7 years, and we were able to keep her with a high quality of life up until the last week, so we are grateful for that.  She will be missed greatly and will always hold a special place in our hearts.',
        ],
    },
    {
        images: [Winnie_Arnold],
        name: 'Winnie',
        texts: [
            'Winnie was our sweet girl. She loved cuddles, snacks and naps - this made her the perfect friend. No words can express how much we will miss are sweet girl.',
        ],
    },
    {
        images: [Bailey_Vohsen_1, Bailey_Vohsen_2, Bailey_Vohsen_3],
        name: 'Bailey',
        texts: [
            'I had to make THE hardest decision of my life today. Bailey has given me 14 amazing years full of love and happiness❤️ I got Bailey when I was 8 years old as a birthday gift. Bailey was not  just a dog to me he was and always will be my soul dog and best friend. He’s been with me almost my whole life. I’m going to miss him every single day for the rest of my life, but I know I’ll be able to see him again some day.❤️ I love you so much Bailey I’m going  to miss you so much my sweet angel 😘',
        ],
    },
    {
        images: [Woodstock_Seufert],
        name: 'Woodstock',
        texts: [
            'Yesterday, I had to say goodbye to my best friend who had been by my side for nearly 15 years. He spent his early days in a shelter, cautious about who he let into his heart. When we first met, he nestled his head in my lap, and that moment, I knew he was special. Initially, I tried fostering him, but our bond was too strong to let him go elsewhere. He filled my life with unwavering love and companionship. Now, my home feels emptier without him.',
            'Over the Fourth of July weekend, he began to struggle. He lost his sight and could only walk for short periods. It was then that I made the heartbreaking decision to let him go peacefully. His final day was filled with cuddles, kisses, and all his favorite treats. I opted for in-home euthanasia to ensure he was comfortable, holding him until the very end.',
        ],
    },
    {
        images: [Buddy_Baldwin],
        name: 'Buddy',
        texts: ['We were so blessed to have such a wonderful pet for 11 years.', 'RIP Buddy.  We will miss you terribly!'],
    },
    {
        images: [Cali_Repple_1, Cali_Repple_2],
        name: 'Cali',
        texts: [
            'Our cat Cali was a very affectionate baby.  She would paw at your chin for attention and rest her head upon your chest - she would sleep in your lap for hours if you let her.  She was such a conversationalist and could make all kinds of friendly noises, and would always reply with a short "mew" if you locked eyes with her or called her name.  She was a precious and special soul and she will forever be a part of our hearts.',
            'Thank you Cali, we love you so much.',
        ],
    },
    {
        images: [Avery_Hazelett],
        name: 'Avery',
        texts: [
            "Our Avery girl. The best dog anyone could've asked for. She gave us and everyone around all the love she had. She made the room brighter, and the days better. She was a constant companion and best friend.",
            "We could not have asked for a better experience and peaceful passing for our best girl. She was surrounded by the people who loved her, in her backyard, listening to the birds and the trees, with a bowl full of fudge brownies. We are so lucky and thankful to have had those last precious moments with her, to see her happy, and to know how loved she was and will always be. It may be quieter around the house, but we know she will always be with us in spirit. If there's a swimming pond over the rainbow bridge, that's where she'll be.  We miss you, and will love you forever, baby Avery.",
        ],
    },
    {
        images: [Sallie_Schuette],
        name: 'Sallie',
        texts: [
            'Sallie, our spirited 14-year-old Australian Shepherd, filled her younger days with her unwavering enthusiasm for chasing cars and motorcycles.  In her later years she spent majority of her time relaxing and herding our family cat.  Sallie leaves behind pawprints of joy and mischief in our hearts.',
        ],
    },
    {
        images: [Rollo_Keimon],
        name: 'Rollo',
        texts: [
            "Rollo, you brought so much joy into our lives. We'll always cherish the memories and great times. You gave us so much happiness. Life won't be the same without you and your Pyr Paw.",
            'Love you Old Man. ',
        ],
    },
    {
        images: [Radar_kitchen_rug, Radar, Radar_handsome_car],
        name: 'Radar',
        texts: [
            'My little guy Radar was the sun and moon. He picked me the day the rescue mentioned they thought we might be a good fit. He had just gotten in trouble (he always had a bit of trouble with some dogs), and they thought he might be good as a single dog. When he fell asleep in my arms walking about Pet Smart, I knew we were meant to be. That was at an estimated 7 months old. He just missed his 16th birthday by a month a half.',
            'It was just us in the beginning, and we had so many adventures! He loved to travel and ride in the car with me. We camped and hung out with family and friends from all over.',
            'When in Uptown Dallas, he got to play with all the other dogs at doggie day car and grooming. When we moved to "his" house (I bought a house for Radar), he got to stay with his favorite grooming/pet sitting family.',
            'He was such a sweet, mild tempered dog. Everyone loved Radar. He would just hang out and enjoy pets and treats without begging for either. His big ears were always a hit whether his hair was long or short.',
            'He got me through some hard times when my Dad passed away and was a loyal companion from start to finish.',
            "About 10 years ago, he moved with me from Texas to Missouri and added 3 more people to his family. Bob and his boys loved and cared for Radar as part of the family. He started getting treats that he'd never had before. He loved them just as much as they love him. We have a garage bar complete with a pub style table where we hang out. Until he was not longer able, Radar had his own chair at the table that was short enough for him to jump in. He was a part of friend's group as much as he was part of the family.",
            'I will miss him so very much. He was the BEST dog and I love him with all of my heart. I love you sweet Radar. I hope you and Ollie are getting to chase each other again across the rainbow bridge.  Until I see you again, little pumpkin!',
        ],
    },
    {
        images: [Oliver_McDonald_1, Oliver_McDonald_2, Oliver_McDonald_3],
        name: 'Oliver',
        texts: [
            'Oliver was the sweetest cat. He would cuddle anyone and everyone. He loved roast beef and catching his mouse toys. There aren\'t adequate words to describe how good he genuinely was. We always called him "The Best Boy". Oliver didn\'t know how to purr quite right. He would make a noise that sounded more like snoring than anything. He tried his best though. Oliver loved his brother Simon. Oliver was my first cat and the hole he left behind is so unbearable.',
        ],
    },
    {
        images: [Clark_Bredenkamp],
        name: 'Clark',
        texts: [
            'This week we said goodbye to our special Clark. He was the sweetest, most gentle dog I’ve had.  He leaves us having lived in 6 states, trekking the east coast many times, many memories, countless walks and hikes, pool parties, birthdays, sleepovers, and all things family. His bestie is Lewis whom the two were inseparable for 12+ years.  Thank you for helping us raise three kids, Clarkus and thank you for being you - a truly great dog.',
        ],
    },
];

export const testimonialsData: { name: string; texts: string[] }[] = [
    {
        name: 'Josh',
        texts: [
            'Of course this decision is never easy, but Shantelle made us feel very comfortable throughout the process. She respected our dog and treated him and us with compassion. We appreciate what she did for us!',
        ],
    },
    {
        name: 'Vicki',
        texts: [
            'Putting a pet down is extremely difficult and I could not bear Sami not spending her last moments in her own home where she is most comfortable.  Dr. Shantelle was able to accommodate our family on short notice and we were so grateful for the kindness and professionalism she showed our family.  We will forever appreciate the comfort that she brought us during this difficult time and providing our beloved Sami with a peaceful passing.',
        ],
    },
    {
        name: 'Zoe',
        texts: [
            'We recently had to say goodbye to our beloved Winnie and was fortunate to have Dr. Shantelle Williams from Passing Paws assist us through this difficult time. Dr. Shantelle was exceptional in every way. Her response was swift, and her calm, professional demeanor made an incredibly challenging situation more bearable. She approached the process with deep compassion and allowed us to take things at our own pace, which meant a great deal to us. Her gentle manner provided a comforting presence during a painful moment.',
            "While we wish we didn't have to say goodbye, we are genuinely grateful for the care and support Dr. Shantelle offered. Her service was truly invaluable.",
        ],
    },
    {
        name: 'Emily',
        texts: [
            'I appreciate Shantelle so much. She made one of our hardest days just a little bit easier. She promptly responded to me when my dog took a turn for the worse and was able to fit us in quickly.',
            'I appreciate her explaining each step and ensuring we had a full understanding of what to expect. She was gentle and gave us the time and peace we needed.',
        ],
    },
    {
        name: 'Bill and Jeanette',
        texts: [
            'Our wonderful 9 1/2 year old pup was stricken with Canine Degenerative Myelopathy which progressed very quickly. In six weeks he went from being able to walk to total paralysis in his hind legs and complete incontinence. We wanted to make sure we did what was right for him and the thought of his last ride to a place that’s already stressful for him did not appeal to us. A co-worker suggested in-home euthanasia and after receiving some referrals we are so pleased we reached out to Dr Shantelle and Passing Paws. She was compassionate, caring, and professional all at once. Our other pets were so relaxed around her my wife said she was a “pet Angel”. For anyone who has to make this difficult decision I would highly recommend in-home euthanasia and Passing Paws.',
        ],
    },
    {
        name: 'Abby',
        texts: [
            'Dr Shantelle was amazing!  She is so kind and compassionate. I loved that she answered all of our questions. I was happy being able to talk everything out before anything happened. I would recommend her to anyone that wanted to do a in home euthanasia. ',
        ],
    },
    {
        name: 'DJ',
        texts: [
            'Passing paws Couldn’t have been a better experience given the circumstances. They were empathetic, kind, compassionate, and did everything they could To make my dog and myself feel as comfortable as possible. ',
        ],
    },
    {
        name: 'Dane and Jodi',
        texts: [
            'Shantelle at Passing Paws was the kindest, most compassionate person.',

            'She treated Buddy with the utmost care.',

            'I highly recommend Passing Paws for in home euthanasia.',

            'Buddy was able to go to rest in his own bed at home. So peaceful.',

            'Thank you Shantelle at Passing Paws!',
        ],
    },
    {
        name: 'Matt',
        texts: [
            'Dr. Shantelle could not have been more accommodating and helpful.  She was very gentle in helping our sweet baby to let go.  Her presence as my family sat with our cat Cali was warm and understanding, I was glad she was there to help us.  I will call her again when it is time to let our other baby let go as well.',
            "I can't recommend her enough. Thank you!",
        ],
    },
    {
        name: 'Gabby',
        texts: [
            'I cannot express how grateful we were to have had Passing Paws help us on one of our worst days.',
            'Dr. Williams made the entire process beautiful and peaceful. She walked us through every step, gave us all the time we needed, and even brought some chocolate kisses for our chocolate girl.',
            'She is kind, thoughtful, and truly cares about the tranquility of the pets and their people during these difficult moments. She made our experience one that we would be happy to share, full of heartwarming and loving memories, and offered us keepsakes that we can look back on forever!',
            'Thank you for helping us to send our baby over the rainbow bridge.',
        ],
    },
    {
        name: 'Barb and Duane',
        texts: [
            'The following kind words don’t even begin to express how I feel about having Dr. Shantelle Williams come to our home and help us during a very difficult time. She was compassionate, took time with explanations, and treated our Buddy with respect and gentleness. I highly recommend Passing Paws and Dr. Shantelle Williams.',
        ],
    },
    {
        name: 'The Schuette Family',
        texts: [
            'Dr. Shantelle with Passing Paws In-Home Pet Euthanasia offers a compassionate and dignified service during one of the most difficult moments as a pet owner. Dr. Shantelle demonstrates immense empathy and understanding, guiding both pet and owner through the process with sensitivity and care. Her attention to detail and commitment to making the experience as gentle as possible sets them apart.',
            'Passing Paws provides support during a time of profound loss, making the transition as smooth and comforting as possible for both pets and their human companions.  Highly recommend and thank you for your service for our sweet Sallie!',
        ],
    },
    {
        name: 'Liz',
        texts: [
            "Dr. Shantelle is a blessing. She understood our circumstance and concern about our dog's quality of life and accommodated our need for day of service.",
            'She is kind, caring and patient. Having an in-house call is a service others should consider in the time of need.',
        ],
    },
    {
        name: 'Dawn',
        texts: [
            'Thank you so much, Dr. Shantelle, for your compassion and kindness during the difficult choice of letting my sweet little guy Radar cross the rainbow bridge. You allowed me to correspond by text and email, which made it easier for me with so much going on.',
            'Your sweet disposition and obvious love for people and animals was comforting. Being able to let my little guy pick his spot to rest at home and not have to be anxious by going to the vet was everything for me. I appreciate that you understood that Radar was family and treated us and him with the upmost respect and care.',
            "I am forever grateful that my last image of my sweet boy was tucked in a basket with a pillow and blanket. Then, your follow up messages, card, and resources for grieving continued the comfort.  You are a blessing and I can't thank you enough.",
        ],
    },
    {
        name: 'Aly',
        texts: [
            "My cat Oliver was diagnosed with bone cancer in his skull on 4/19/2024. The vet said he wasn't sure how many good days Oliver would have left. He said we would need to consider euthanasia and very soon at that.",
            "Shantelle was very professional and helpful. We thought Oliver had more time than he did, and she helped us reschedule at least twice to move it closer up since our boy was clearly suffering. Shantelle is very helpful and she explained the entire process then asked us if we had any questions. She was amazing to my Oliver. She's very gentle and she treated my cat like he was an old friend of hers.",
            'Thank you for helping my Ollie pass peacefully in my lap surrounded by his family. ❤️🐈‍⬛️',
        ],
    },
    {
        name: 'Traci',
        texts: [
            'My experience with Passing Paws was the best I could have asked for. When I was sure it was the end of our beloved Clark’s time I reached out to Passing Paws and they responded quickly and could come the next day.',
            'Shantelle was knowledgable, professional, could answer our questions and efficiently guided us through the process. I am especially grateful for the first sedation shot because we were able to see our dog (clearly struggling and in pain) shift into a warm, relaxed state and he looked so much at peace as we were able to spend our last moments with him, in our home where he was comfortable. This really meant a lot. I would recommend Passing Paws to anyone in a similar situation. Even though a very sad day Shantelle made this process a lot easier on Clark and the whole family.',
        ],
    },
];

export const emergencyReferrals = [
    {
        name: 'Veterinary Specialty Services West',
        url: 'https://www.vssstl.com/locations/veterinary-specialty-services-west',
        phone: '636-244-8999',
    },
    {
        name: "Animal Emergency Clinic O' Fallon",
        url: 'https://www.stlouisanimalemergencyclinic.org/site/contact-animal-hospital-o-fallon-vet',
        phone: '636-240-5496',
    },
];

export const email = 'info@passingpawsihe.com';

export const phone = '636-755-4247';
